import { useMemo } from 'react';
import { League, useLeaguesQuery } from 'src/api';

export function useLeagues(): League[] {
  const leaguesData = useLeaguesQuery().data;
  return useMemo(() => leaguesData?.leagues.edges.map((edge) => edge.node).reverse() ?? [], [leaguesData]);
}

export function useLeagueNames(leagueIds: string[]): string {
  const leagues = useLeagues();
  const leagueIdsSet = new Set(leagueIds);
  return leagues
    ?.filter((league) => leagueIdsSet.has(league.id))
    .map((league) => league.name)
    .join(', ');
}
